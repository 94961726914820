import React from "react"
import { navigate } from "gatsby"
import { useAnimation } from "framer-motion"

import {
  NavigationContainer,
  MenuList,
  ListPrimary,
  MenuItem,
  SocialContainer,
  Instagram,
  Twitter,
  LinkedIn,
  Facebook,
} from "./../styles/navigation"

// context
import { useGlobalDispatchContext } from "./../context/globalContext"
import { types, CursorType } from "./../constants"

import Cursor from "./Cursor"
import { useEffect } from "react"

const Navigation = props => {
  const dispatch = useGlobalDispatchContext()
  const controls = useAnimation()

  useEffect(() => {
    controls.start({
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeIn",
        duration: 0.3,
        delay: 0.6,
      },
    })
  }, [])

  const navigateTo = (ev, to) => {
    ev.preventDefault()

    const doNavigation = () => {
      navigate(to)
      setTimeout(() => {
        dispatch({
          type: types.NAV_IS_OPEN,
          navIsOpen: false,
        })
        dispatch({
          type: types.CURSOR_TYPE,
          cursorType: CursorType.DEFAULT,
        })
      }, 500)
    }

    controls
      .start({
        opacity: 0,
        y: "100%",
        transition: {
          type: "tween",
          ease: "easeOut",
          duration: 0.5,
        },
      })
      .then(() => {
        doNavigation()
      })
  }

  return (
    <NavigationContainer>
      <MenuList>
        <ListPrimary>
          <MenuItem animate={controls}>
            <Cursor.Hover big>
              <span
                role="button"
                tabIndex="-1"
                aria-label="Our Works"
                onClick={ev => navigateTo(ev, "/works")}
                onKeyDown={ev => navigateTo(ev, "/works")}
              >
                <h1>Our Works</h1>
              </span>
            </Cursor.Hover>
          </MenuItem>
          <MenuItem animate={controls}>
            <Cursor.Hover big>
              <span
                role="button"
                tabIndex="-1"
                aria-label="About us"
                onClick={ev => navigateTo(ev, "/about")}
                onKeyDown={ev => navigateTo(ev, "/about")}
              >
                <h1>About Us</h1>
              </span>
            </Cursor.Hover>
          </MenuItem>
          <MenuItem animate={controls}>
            <Cursor.Hover big>
              <span
                role="button"
                tabIndex="-1"
                aria-label="Contact us"
                onClick={ev => navigateTo(ev, "/contact")}
                onKeyDown={ev => navigateTo(ev, "/contact")}
              >
                <h1>Contact Us</h1>
              </span>
            </Cursor.Hover>
          </MenuItem>
        </ListPrimary>
        <SocialContainer animate={controls}>
          <a href="https://dribbble.com/mellowframes" target="_blank">
            <li>
              <Instagram />
            </li>
          </a>
          <a href="https://dribbble.com/mellowframes" target="_blank">
            <li>
              <LinkedIn />
            </li>
          </a>
          <a href="https://dribbble.com/mellowframes" target="_blank">
            <li>
              <Facebook />
            </li>
          </a>
          <a href="https://dribbble.com/mellowframes" target="_blank">
            {" "}
            <li>
              <Twitter />
            </li>
          </a>
        </SocialContainer>
      </MenuList>
    </NavigationContainer>
  )
}

export default Navigation
