import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"

// styled components
import { HeaderContainer, HamContainer, CloseButton } from "./../styles/header"
import { Container, Flex } from "./../styles/GlobalStyles"

// context
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "./../context/globalContext"
import { types, CursorType } from "./../constants"

// components
import Cursor from "./../components/Cursor"
import { IoIosClose } from "react-icons/io"

const Ham = () => {
  const dispatch = useGlobalDispatchContext()
  const { navIsOpen } = useGlobalStateContext()

  const toggleNav = () => {
    dispatch({
      type: types.NAV_IS_OPEN,
      navIsOpen: navIsOpen ? false : true,
    })
  }

  return (
    <Cursor.Hover>
      {navIsOpen ? (
        <CloseButton aria-label="Close" onClick={toggleNav}>
          <IoIosClose size={50} />
        </CloseButton>
      ) : (
        <HamContainer aria-label="Navbar" onClick={toggleNav}>
          <span />
          <span />
          <span />
        </HamContainer>
      )}
    </Cursor.Hover>
  )
}

const Header = () => {
  const { currentTheme, navIsOpen } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const data = useStaticQuery(graphql`
    query {
      dark: file(relativePath: { eq: "logo-dark.png" }) {
        childImageSharp {
          fixed(height: 26, width: 48, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      light: file(relativePath: { eq: "logo-light.png" }) {
        childImageSharp {
          fixed(height: 26, width: 48, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      full: file(relativePath: { eq: "logo-full.png" }) {
        childImageSharp {
          fixed(height: 26, width: 240, fit: INSIDE) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  let logo = data[currentTheme]

  if (navIsOpen) {
    logo = data.full
  }

  const goToHome = ev => {
    ev.preventDefault()
    navigate("/")
    dispatch({
      type: types.CURSOR_TYPE,
      cursorType: CursorType.DEFAULT,
    })
    if (navIsOpen) {
      dispatch({
        type: types.NAV_IS_OPEN,
        navIsOpen: false,
      })
    }
  }

  return (
    <HeaderContainer>
      <Container>
        <Flex align="center" justify="between">
          <Cursor.Hover big>
            <div
              role="button"
              aria-label="Mellowframes"
              tabIndex="-1"
              onClick={ev => goToHome(ev)}
              onKeyDown={ev => goToHome(ev)}
            >
              <Img fixed={logo.childImageSharp.fixed} />
            </div>
          </Cursor.Hover>
          <Ham />
        </Flex>
      </Container>
    </HeaderContainer>
  )
}

export default Header
