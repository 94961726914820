import React from "react"
import PropTypes from "prop-types"

// styled components
import { ThemeProvider } from "styled-components"
import {
  GlobalStyle,
  PageContainer,
  PageAnimContainer,
} from "./../styles/GlobalStyles"

// theme
import theme from "./../styles/themes"

// context
import { useGlobalStateContext } from "./../context/globalContext"

// components
import Cursor from "./Cursor"
import Header from "./Header"
import Navigation from "./Navigation"

const Layout = ({ children }) => {
  const { currentTheme, navIsOpen } = useGlobalStateContext()

  return (
    <ThemeProvider theme={theme[currentTheme]}>
      <GlobalStyle />

      <main className={navIsOpen ? "nav-open" : ""}>
        <Cursor />
        <Header />
        <PageAnimContainer>
          <PageContainer>{children}</PageContainer>
        </PageAnimContainer>
        {navIsOpen ? <Navigation /> : null}
      </main>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
