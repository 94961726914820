const lightTheme = {
  headerBlur: "rgba(255,255,255,0.7)",
  background: "#FFFFFF",
  primary: "#F9015A",
  text: "#3C4856",
  text2: "#3C4856",
  cursor: "#F9015A",
  ham: "#F9015A",
  hamHover: "#100818",
  overlay: "#F5F9FB",
  clientsBg: "#F5F9FB",

  logoText: "#100818",
}
const darkTheme = {
  headerBlur: "rgba(16, 8, 24, 0.5)",
  background: "#100818",
  primary: "#F9015A",
  text: "#9FA9AE",
  text2: "#E7E7E8",
  cursor: "#F9015A",
  ham: "#F9015A",
  hamHover: "#FFFFFF",
  overlay: "#1E1528",
  clientsBg: "#140a1f",

  logoText: "#FFFFFF",
}

const theme = {
  light: lightTheme,
  dark: darkTheme,
}

export default theme
