import styled from "styled-components"
import { motion } from "framer-motion"

export const HeaderContainer = styled(motion.header).attrs({
  className: "header-container",
})`
  height: 90px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background: ${({ theme: { headerBlur } }) => headerBlur};
  box-sizing: border-box;

  @media (max-width: 599px) {
    height: 70px;
  }

  @media (min-width: 600px) {
    height: 80px;
  }

  @media (min-width: 900px) {
    height: 90px;
  }
`

export const HamContainer = styled(motion.button).attrs({
  className: "ham-container",
  whileTap: { scale: 0.8 },
})`
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
  width: 60px;
  padding-left: 18px;

  span {
    height: 2px;
    background: ${({ theme: { ham } }) => ham};
    margin: 6px 0;
    display: block;
    transition: all 200ms ease-in-out;
    transition-property: background, width;
    border-radius: 3px;
  }

  span:nth-child(1) {
    width: 24px;
  }
  span:nth-child(2) {
    width: 21px;
  }
  span:nth-child(3) {
    width: 18px;
  }

  &:hover {
    span {
      background: ${({ theme: { hamHover } }) => hamHover};
    }
  }

  @media (max-width: 599px) {
    margin: 4px 0;
    span:nth-child(1) {
      width: 22px;
    }
    span:nth-child(2) {
      width: 19px;
    }
    span:nth-child(3) {
      width: 16px;
    }
  }

  @media (min-width: 600px) {
    margin: 5px 0;
    span:nth-child(1) {
      width: 23px;
    }
    span:nth-child(2) {
      width: 20px;
    }
    span:nth-child(3) {
      width: 17px;
    }
  }

  @media (min-width: 900px) {
    margin: 6px 0;

    span:nth-child(1) {
      width: 24px;
    }
    span:nth-child(2) {
      width: 21px;
    }
    span:nth-child(3) {
      width: 18px;
    }
  }
`
export const CloseButton = styled(motion.button).attrs({
  whileTap: { scale: 0.8 },
})`
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  padding-left: 25px;
`
