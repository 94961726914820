import styled from "styled-components"
import { motion } from "framer-motion"

import {
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoFacebook,
  IoLogoTwitter,
} from "react-icons/io"

export const NavigationContainer = styled(motion.div).attrs({
  className: "nav-container",
  initial: { opacity: 0, width: 0 },
  animate: { opacity: 1, width: "100%" },
  transition: { type: "tween", ease: "linear", duration: 0.3 },
})`
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background-color: ${({ theme: { primary } }) => primary};
  list-style: none;
  transition: all 0.2s ease-in-out;
  will-change: top;
  z-index: 1100;
  overflow: hidden;
`

export const MenuList = styled.div`
  position: absolute;
  top: 35%;
  right: 275px;
  left: 0;
  width: 100%;
  min-width: 320px;
  transform: translateY(-30%);
  padding-left: 20px;

  @media (max-width: 599px) {
    top: 25%;
    padding-left: 20px;
  }

  @media (min-width: 415px) {
    left: auto;
    width: auto;
    right: auto;
  }

  @media (min-width: 600px) {
    right: 100px;
  }
  @media (min-width: 900px) {
    right: 275px;
  }
`

export const ListPrimary = styled.ul`
  list-style: none;

  @media (max-width: 599px) {
    margin-bottom: 20px;
  }

  @media (min-width: 415px) {
    margin-top: 0;
    margin-bottom: 38px;
    padding-right: 30px;
  }

  @media (min-width: 829px) {
    padding-right: 0;
  }
`

export const MenuItem = styled(motion.li).attrs({
  initial: { opacity: 0, y: 200 },
})`
  margin-bottom: 15px;
  span {
    color: #fff;
    text-decoration: none;
    transition: color 0.15s;

    h1 {
      color: #000;
      font-weight: 700;
    }

    &:hover {
      transform: scale(1.3);
    }
  }

  @media (min-width: 415px) {
    padding: 0.75rem 0.75rem 0.75rem 0.1rem;
    span {
      font-size: 22px;
    }
  }
  @media (min-width: 829px) {
    span {
      font-size: 30px;
    }
  }
  @media (min-width: 1201px) {
    max-width: 900px;
  }
`

export const SocialContainer = styled(motion.ul).attrs({
  initial: { opacity: 0, y: 200 },
})`
  display: flex;
  margin-left: 0.75rem;

  li {
    margin-right: 1rem;
  }

  @media (max-width: 599px) {
    margin-left: 0.2rem;
    margin-top: 50px;
  }
`

export const Instagram = styled(IoLogoInstagram)`
  font-size: 1.3rem;
  color: #e7e7e8;
  margin-right: 10px;
`
export const LinkedIn = styled(IoLogoLinkedin)`
  font-size: 1.3rem;
  color: #e7e7e8;
  margin-right: 10px;
`
export const Facebook = styled(IoLogoFacebook)`
  font-size: 1.3rem;
  color: #e7e7e8;
  margin-right: 10px;
`
export const Twitter = styled(IoLogoTwitter)`
  font-size: 1.3rem;
  color: #e7e7e8;
`
