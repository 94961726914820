import React, { useState, useEffect } from "react"

import { Cursor as CursorComponent } from "./../styles/GlobalStyles"
import { types, CursorType } from "./../constants"

import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "./../context/globalContext"

const Cursor = () => {
  const { cursorType } = useGlobalStateContext()
  const [mousePosition, setMousePosition] = useState({
    x: 250,
    y: 250,
  })

  const onMouseMove = event => {
    const { pageX: x, pageY: y } = event
    setMousePosition({ x, y })
  }

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)

    return () => {
      document.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  return (
    <CursorComponent
      className={`cursor-container ${cursorType}`}
      style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
    />
  )
}

const Hover = ({ children, big }) => {
  const dispatch = useGlobalDispatchContext()

  const type = big ? CursorType.BIG : CursorType.HOVER

  return (
    <div
      role="button"
      tabIndex="-1"
      aria-label="cursor"
      onMouseEnter={() =>
        dispatch({ type: types.CURSOR_TYPE, cursorType: type })
      }
      onMouseLeave={() =>
        dispatch({ type: types.CURSOR_TYPE, cursorType: CursorType.DEFAULT })
      }
    >
      {children}
    </div>
  )
}

Cursor.Hover = Hover

export default Cursor
